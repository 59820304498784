.userMenu {
    .userMenu-container {
      position: relative;
      top: 50px;
      width: 100%;
      background-color: white;
      border-radius: 3px;
      box-shadow: 0px 1px 3px rgba(black, 0.2);
    }
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 8em;
    cursor: default;
    ul {
      display: flex;
      flex-flow: column nowrap;
      margin: 0;
      padding: 0;
      align-items: flex-start;
    }
    .spacer {
        height: 5px;
    }
    li {
      display: block;
      font-size: 1em;
      line-height: 2em;
      padding: 0 .5em;
      cursor: pointer;
      width: 100%;
      box-sizing: border-box;
      text-align: start;
      button {
        width: 100%;
      }
    }
  }
  .userMenu.open {
    display: inline;
  }