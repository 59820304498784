html {
  height: 100%;
}

body {
  height: 100%;
}

.appContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  max-width: 60em;
  height: 100%;
}

.appHeader {
  font-size: 1.5em;
  font-weight: 100;
  height: 3em;
  line-height: 3em;
  padding: 0px 1em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  .nav {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-evenly;
  }
}

.appContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  .card {
    display: flex;
    flex-direction: column;
    padding: 100px;
    border-radius: 3px;
    box-shadow: 0px 1px 3px rgba(black, 0.2);
    margin: 1.5em;
    padding: 1em;
    flex: 1 0 10em;
    .cardTitle {
      font-size: 1.3em;
      font-weight: 200;
      flex: 0 0 1.5em;
    }
    .cardContent {
      font-weight: 200;
      flex: 1 1 50px;
      text-align: justify;
      line-height: 1.2em;
    }
  }
  .card-pink {
    background-color: lightpink;
  }
  flex: 1 0 100px;
}

.appFooter {
  flex: 0 0 3em;
  padding: 1.5em;
  font-weight: 200;
  font-size: .8em;
  color:rgba($color: black, $alpha: .5);
}