button {
    height: 2.2em;
    padding: 5px 20px;
    color: black;
    font-size: .8em;
    cursor: pointer;
    font-weight: inherit;
    font-family: inherit;
    border: none;
    background-color: inherit;
    border-radius: 30px;
    transition: background-color .5s;
    position: relative;
  }
  button:hover {
    background-color: rgba(black, .05);
  }